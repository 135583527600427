

:root {
  /* Color Variables */
  --primary-color: #2563eb;
  --primary-light: #3b82f6;
  --primary-dark: #1d4ed8;
  --secondary-color: #64748b;
  --accent-color: #8b5cf6;
  
  /* Background Colors */
  --bg-main: #f8fafc;
  --bg-sidebar: #ffffff;
  --bg-message-unread: #eff6ff;
  --bg-message-read: #f1f5f9;
  --bg-hover: #e2e8f0;
  
  /* Text Colors */
  --text-primary: #1e293b;
  --text-secondary: #64748b;
  --text-light: #94a3b8;
  
  /* Border Colors */
  --border-color: #e2e8f0;
  --border-active: #3b82f6;
  
  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  
  /* Border Radius */
  --radius-sm: 0.375rem;
  --radius-md: 0.5rem;
  --radius-lg: 0.75rem;
  --radius-full: 9999px;
  
  /* Shadows */
  --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1);
}

/* Layout */
.container {
  min-height: 100vh;
  background-color: var(--bg-main);
}

.main-content {
  padding: var(--spacing-md);
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 320px;
  background-color: var(--bg-sidebar);
  box-shadow: var(--shadow-lg);
  transition: transform 300ms ease-in-out;
}

.sidebar-hidden {
  transform: translateX(-100%);
}

.sidebar-visible {
  transform: translateX(0);
}

.sidebar-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Header */
.header {
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Buttons */
.notification-button {
  position: fixed;
  left: var(--spacing-md);
  top: var(--spacing-md);
  padding: var(--spacing-sm);
  background-color: var(--bg-sidebar);
  border-radius: var(--radius-full);
  border-color: transparent;
  box-shadow: var(--shadow-lg);
  transition: all 0.2s;
}

.notification-button:hover {
  background-color: var(--bg-hover);
}

.tab-container {
  display: flex;
  gap: var(--spacing-md);
}

.tab-button {
  padding: var(--spacing-sm);
  border-radius: var(--radius-md);
  border-color: transparent;
  transition: all 0.2s;
  border-color: transparent;
}

.tab-button.active {
  background-color: var(--bg-message-unread);
  color: var(--primary-color);
}

.tab-button:not(.active):hover {
  background-color: var(--bg-hover);
}

.close-button {
  padding: var(--spacing-sm);
  border-radius: var(--radius-md);
  border-color: transparent;
  transition: all 0.2s;
  border-color: transparent;
}

.close-button:hover {
  background-color: var(--bg-hover);
}

/* Messages */
.message-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.message-item {
  padding: var(--spacing-md);
  border-radius: var(--radius-lg);
  border-color: transparent;
  cursor: pointer;
  transition: all 0.2s;
}

.message-item.unread {
  background-color: var(--bg-message-unread);
}

.message-item:not(.unread) {
  background-color: var(--bg-message-read);
}

.message-item:hover:not(.unread) {
  background-color: var(--bg-hover);
}

.message-content {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-sm);
}

.avatar {
  padding: var(--spacing-sm);
  border-color: transparent;
  border-radius: var(--radius-full);
  background-color: var(--bg-hover);
}

.message-info {
  flex: 1;
}

.message-header {
  display: flex;
  align-items: center;
  border-color: transparent;
  justify-content: space-between;
}

.sender-name {
  font-weight: 500;
  color: var(--text-primary);
}

.message-time {
  font-size: 0.75rem;
  color: var(--text-secondary);
}

.message-text {
  font-size: 0.875rem;
  margin-top: 4px;
  color: var(--text-secondary);
}

/* Chat */
.chat-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-main);
}

.chat-header {
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--border-color);
  border-color: transparent;
}

.back-button {
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-md);
  background-color: var(--bg-hover);
  border-color: transparent;
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
  font-weight: 500;
  transition: all 0.2s;
}

.back-button:hover {
  background-color: var(--border-color);
  border-color: transparent;
}

.user-info {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.user-avatar {
  padding: var(--spacing-sm);
  border-radius: var(--radius-full);
  border-color: transparent;
  background-color: var(--bg-hover);
}

.user-name {
  font-weight: 500;
  color: var(--text-primary);
}

.user-status {
  font-size: 0.875rem;
  color: var(--text-secondary);
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: var(--spacing-md);
}

.input-container {
  border-top: 1px solid var(--border-color);
  border-color: transparent;
  padding: var(--spacing-md);
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-sm);
}

.action-button {
  padding: var(--spacing-sm);
  border-radius: var(--radius-full);
  background-color: var(--bg-hover);
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.action-button.active {
  background-color: var(--primary-light);
  color: white;
}

.message-input {
  display: flex;
  gap: var(--spacing-sm);
}

.textarea-container {
  flex: 1;
  position: relative;
}

.message-textarea {
  width: 100%;
  padding: var(--spacing-md);
  padding-right: calc(var(--spacing-md) + 40px);
  border: 1px solid var(--border-color);
  border-color: transparent;
  border-radius: var(--radius-md);
  background-color: var(--bg-main);
  color: var(--text-primary);
  resize: none;
  outline: none;
}

.message-textarea:focus {
  border-color: var(--border-active);
  box-shadow: var(--shadow-sm);
}

.emoji-button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  padding: var(--spacing-sm);
  border-radius: var(--radius-full);
  background-color: var(--bg-hover);
  color: var(--text-secondary);
  transition: all 0.2s;
}

.send-button {
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-md);
  background-color: var(--bg-hover);
  color: var(--text-secondary);
  transition: all 0.2s;
  opacity: 0.7;
}

.send-button.active {
  background-color: var(--primary-color);
  color: white;
  opacity: 1;
}

.send-button.active:hover {
  background-color: var(--primary-dark);
}

/* Notifications */
.notification-container {
  padding: var(--spacing-md);
}

.notification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-md);
}

.notification-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-primary);
}

.settings-button {
  padding: var(--spacing-sm);
  border-radius: var(--radius-md);
  transition: all 0.2s;
}

.settings-button:hover {
  background-color: var(--bg-hover);
}

.notification-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.notification-item {
  padding: var(--spacing-md);
  border-radius: var(--radius-lg);
}

.notification-item.unread {
  background-color: var(--bg-message-unread);
}

.notification-item.read {
  background-color: var(--bg-message-read);
}

.notification-content {
  display: flex;
  gap: var(--spacing-sm);
  align-items: flex-start;
}

.notification-icon {
  margin-top: 4px;
  padding: var(--spacing-sm);
  border-radius: var(--radius-full);
}

.notification-icon.unread {
  background-color: var(--primary-light);
  color: white;
}

.notification-icon.read {
  background-color: var(--bg-hover);
  color: var(--text-secondary);
}