/* General Reset for All Browsers */
body, html {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Disable scroll for all browsers */
  height: 100vdh;
  width: 100vw;
  overscroll-behavior: contain;
  scrollbar-width: none;
}

/* Smooth font rendering */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}

/* Scrollbar Styling */
/* WebKit Browsers (Chrome, Safari, Edge) */
body::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  display: none;
  scrollbar-width: none;

}

/* Firefox */
body {
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}

/* IE and Edge */
body {
  -ms-overflow-style: none; /* Hides scrollbar for IE and Edge */
}

/* Font-face Definitions */
@font-face {
  font-family: "Black";
  src: local("Roboto-Black"),
    url("./assets/fonts/Roboto-Black.ttf") format("truetype");
}

@font-face {
  font-family: "markerFelt";
  src: local("MarkerFelt"),
    url("./assets/fonts/Marker\ Felt.ttf") format("truetype");
}

@font-face {
  font-family: "lemonfont";
  src: local("Lemon-Regular"),
    url("./assets/fonts/Lemon-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "raleway";
  src: local("Raleway-Light"),
    url("./assets/fonts/Raleway-Light.ttf") format("truetype");
}

/* Animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App Styling */
.App {
  text-align: center;
}
.container {
  overscroll-behavior: none; /* Prevent scroll chaining and overscroll */
  overflow: auto; /* Ensure scrolling works normally otherwise */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* General Reset for All Browsers */
body, html {
  margin: 0;
  padding: 0;
  overflow: hidden;

  overscroll-behavior: contain;
}

/* Smooth font rendering */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}

/* Hide Scrollbar */

/* WebKit Browsers (Chrome, Safari, Edge) */
body::-webkit-scrollbar {
  width: 0px;  /* Hide scrollbar width */
  height: 0px; /* Hide scrollbar height */
  display: none; /* Ensures scrollbar is hidden */
}

/* Firefox */
body {
  scrollbar-width: none; /* Firefox-specific scrollbar hiding */
}

/* Internet Explorer and Edge (Legacy) */
body {
  -ms-overflow-style: none; /* IE and Edge scrollbar hiding */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

/* Browser-Specific Fixes */

/* Chrome and Safari */
@supports (-webkit-appearance: none) {
  .App-header {
    border: 1px solid red; /* Example adjustment for Chrome/Safari */
  }
}

/* Firefox */
@-moz-document url-prefix() {
  .App-header {
    border: 1px solid blue; /* Example adjustment for Firefox */
  }
}

/* Internet Explorer */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .App-header {
    border: 1px solid green; /* Example adjustment for IE */
  }
}

/* Edge */
@supports (-ms-ime-align: auto) {
  .App-header {
    border: 1px solid yellow; /* Example adjustment for Edge */
  }
}
