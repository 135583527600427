.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  body, html {
    margin: 0; /* Reset margins */
    padding: 0; /* Reset padding */
    /* scrollbar-width: none; */
    /* overflow: hidden; */
  
  }
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    overflow: hidden;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  body {
  margin: 0;
  overflow:hidden;
  padding: 0;
  }
  body, html {
    margin: 0; /* Reset margins */
    padding: 0; /* Reset padding */
    /* overflow:hidden; */
    overflow: hidden;
  }
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  body::-webkit-scrollbar {
    width: 0px;
  }
  
  body {
    height: 100vh;
    width: 100vw;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  /* WebKit browsers */ 
  ::-webkit-scrollbar { 
    width: 0px; 
    height: 0px; 
  } 
  
  ::-webkit-scrollbar-thumb { 
    background-color: darkgrey; 
    border-radius: 10px; 
  } 
  
  ::-webkit-scrollbar-track { 
    background: #f1f1f1; 
  } 
  @font-face {
    font-family: "Black";   /*Can be any text*/
    src: local("Roboto-Black"),
      url("./assets/fonts/Roboto-Black.ttf") format("truetype");
  }
  @font-face {
    font-family: "markerFelt";   /*Can be any text*/
    src: local("markerFelt"),
      url("./assets/fonts/Marker\ Felt.ttf") format("truetype");
  }
  @font-face {
    font-family: "Black";   /*Can be any text*/
    src: local("Roboto-Black"),
      url("./assets/fonts/Roboto-Black.ttf") format("truetype");
  }
  @font-face {
    font-family: "Black";   /*Can be any text*/
    src: local("Roboto-Black"),
      url("./assets/fonts/Roboto-Black.ttf") format("truetype");
  }
  @font-face {
    font-family: "lemonfont";   /*Can be any text*/
    src: local("lemonfont"),
      url("./assets/fonts/Lemon-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: "raleway";   /*Can be any text*/
    src: local("raleway"),
      url("./assets/fonts/Raleway-Light.ttf") format("truetype");
  }